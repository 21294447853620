import type { FC } from 'react';
import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Select from '@atlaskit/select';
import type { SingleValue } from '@atlaskit/react-select';
import { Box } from '@atlaskit/primitives';

const i18n = defineMessages({
	speedLabel08x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-08x',
		defaultMessage: '0.8x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 0.8x',
	},
	speedLabel10x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-10x',
		defaultMessage: '1.0x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 1.0x',
	},
	speedLabel12x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-12x',
		defaultMessage: '1.2x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 1.2x',
	},
	speedLabel15x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-15x',
		defaultMessage: '1.5x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 1.5x',
	},
	speedLabel17x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-17x',
		defaultMessage: '1.7x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 1.7x',
	},
	speedLabel20x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-20x',
		defaultMessage: '2.0x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 2.0x',
	},
});

// Don't show any indicator
const EmptySelectIndicator = () => <></>;

export type MiniplayerPlaybackSpeedControlProps = {
	onChangePlaybackSpeed: (newSpeed: number) => void;
};

export const MiniplayerPlaybackSpeedControl: FC<MiniplayerPlaybackSpeedControlProps> = ({
	onChangePlaybackSpeed,
}) => {
	const intl = useIntl();

	const PLAYBACK_RATES = useMemo(
		() => [
			{ label: intl.formatMessage(i18n.speedLabel08x), value: 0.8 },
			{ label: intl.formatMessage(i18n.speedLabel10x), value: 1.0 },
			{ label: intl.formatMessage(i18n.speedLabel12x), value: 1.2 },
			{ label: intl.formatMessage(i18n.speedLabel15x), value: 1.5 },
			{ label: intl.formatMessage(i18n.speedLabel17x), value: 1.7 },
			{ label: intl.formatMessage(i18n.speedLabel20x), value: 2.0 },
		],
		[intl],
	);

	const onInitiateChangePlaybackSpeed = (
		newValue: SingleValue<{ label: string; value: number }>,
	) => {
		onChangePlaybackSpeed(newValue ? newValue.value : 1.0);
	};

	return (
		<Box testId="miniplayer-playback-speed">
			<Select
				appearance="subtle"
				defaultValue={[PLAYBACK_RATES[1]]}
				onChange={onInitiateChangePlaybackSpeed}
				options={PLAYBACK_RATES}
				menuPlacement="auto"
				spacing="compact"
				components={{ DropdownIndicator: EmptySelectIndicator }}
			/>
		</Box>
	);
};
