import gql from 'graphql-tag';

export const MainLayoutQuery = gql`
	query MainLayoutQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			lookAndFeel {
				content {
					screen {
						background
						backgroundColor
						backgroundImage
						backgroundPosition
						backgroundSize
						backgroundRepeat
						backgroundOrigin
						backgroundClip
						backgroundAttachment
						backgroundBlendMode
					}
				}
			}
		}
	}
`;
