import React, { useState, useEffect, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import CloseIcon from '@atlaskit/icon/core/close';
import ProgressBar from '@atlaskit/progress-bar';
import { Box, Stack, Flex } from '@atlaskit/primitives';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { Card } from '@atlaskit/smart-card';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { useAudioPlaybackState } from '../hooks/useAudioPlaybackState';
import { getContentUrl } from '../utils/getContentUrl';

import { MiniplayerControls } from './MiniplayerControls';

const SKIP_SECONDS = 10;
const START_SECONDS = 0;

const i18n = defineMessages({
	closeButtonLabel: {
		id: 'audio.miniplayer.close-label',
		defaultMessage: 'Close',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will stop audio playback and close the player controls',
	},
});

export const Miniplayer = () => {
	const intl = useIntl();
	const [
		{ activeContent, audioContext, isPlaying, audioSrcUrl },
		{ onContentStopped, onContentPaused, onContentResumed, onContentError },
	] = useAudioPlaybackState();
	const audioRef = useRef<HTMLAudioElement>(null);
	const [elapsedTime, setElapsedTime] = useState(0);
	const { trackAIResultAction, trackAIResultView } = useAIEventsInstrumentation();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const interval = setInterval(() => {
			if (audioRef.current?.currentTime) {
				setElapsedTime(audioRef.current.currentTime - START_SECONDS);
			}
		}, 500); // Half second for smoother progress bar movement for various playback speeds
		return () => {
			clearInterval(interval);
		};
	}, [audioContext, isPlaying]);

	useEffect(() => {
		const playAudio = async () => {
			const audio = audioRef.current;
			if (audio && audioSrcUrl && audio.src !== audioSrcUrl) {
				audio.src = audioSrcUrl;
				audio.load();
				await audio.play();
			}
		};

		playAudio().catch((e) => onContentError(e));
	}, [audioSrcUrl, onContentStopped, onContentError]);

	useEffect(() => {
		trackAIResultView();

		const audio = audioRef.current;

		const onPaused = () => {
			onContentPaused();
		};

		const onPlayed = () => {
			onContentResumed();
		};

		if (audio) {
			audio.addEventListener('pause', onPaused);
			audio.addEventListener('play', onPlayed);
		}

		return () => {
			if (audio) {
				audio.removeEventListener('pause', onPaused);
				audio.removeEventListener('play', onPlayed);
				trackAIResultAction('audioClosed');
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const sendAnalyticsUIEvent = (
		actionSubjectId: string,
		additionalAttributes?: Record<string, number | string>,
	) => {
		const attributes = {
			audioType: activeContent?.sourceType,
			summaryLocale: intl.locale,
			...additionalAttributes,
		};
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'miniplayer',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId,
				attributes,
			},
		}).fire();
		trackAIResultAction(`${actionSubjectId}Clicked`, { attributes });
	};

	const getProgressPercentage = (): number => {
		return audioRef.current?.duration ? elapsedTime / audioRef.current.duration : 0;
	};

	const onInitiateClose = async () => {
		await onContentStopped();
	};

	const onInitiatePause = () => {
		if (audioRef.current) {
			sendAnalyticsUIEvent('audioPause');
			audioRef.current.pause();
		}
	};

	const onInitiateResume = async () => {
		if (audioRef.current) {
			sendAnalyticsUIEvent('audioPlay');
			await audioRef.current.play();
		}
	};

	const onInitiateSkipBack = () => {
		const audio = audioRef.current;
		if (audio) {
			sendAnalyticsUIEvent('audioSkipBackward');
			if (audio.currentTime <= SKIP_SECONDS) {
				audio.currentTime = START_SECONDS;
			} else {
				audio.currentTime -= SKIP_SECONDS;
			}
		}
	};

	const onInitiateSkipForward = () => {
		const audio = audioRef.current;
		if (audio && audio.duration) {
			sendAnalyticsUIEvent('audioSkipForward');
			if (audio.currentTime >= audio.duration - SKIP_SECONDS) {
				audio.currentTime = START_SECONDS;
				setTimeout(() => audio.pause(), 100); // Small delay to ensure current time is reset and rendered before pausing
			} else {
				audio.currentTime += SKIP_SECONDS;
			}
		}
	};

	const onChangePlaybackSpeed = (newSpeed: number) => {
		if (audioRef.current) {
			sendAnalyticsUIEvent('audioPlayBackSpeed', { playbackSpeed: newSpeed });
			audioRef.current.playbackRate = newSpeed;
		}
	};

	const onInitiatePlayFromBeginning = async () => {
		const audio = audioRef.current;
		if (audio) {
			sendAnalyticsUIEvent('audioPlayFromBeginning');
			audio.currentTime = START_SECONDS;
			if (audio.paused) {
				await audio.play();
			}
		}
	};

	type CallbackType = (() => any) | (() => Promise<any>);
	const tryCallback = async (callback: CallbackType) => {
		try {
			await callback();
		} catch (e) {
			onContentError(e);
		}
	};

	const contentUrl = useMemo(() => {
		return activeContent ? getContentUrl(activeContent) : null;
	}, [activeContent]);

	return (
		<Box paddingBlock="space.150" paddingInline="space.150">
			{/* eslint-disable-next-line jsx-a11y/media-has-caption -- The summary is already available in text so the track isn't very necessary */}
			<audio data-testid="miniplayer-audio" ref={audioRef} autoPlay />
			<Stack space="space.150">
				<Flex columnGap="space.100" justifyContent="space-between">
					<Box>
						{contentUrl && (
							<SmartCardProvider product="CONFLUENCE">
								<Card appearance="inline" url={contentUrl} truncateInline />
							</SmartCardProvider>
						)}
					</Box>
					<Box>
						<IconButton
							icon={CloseIcon}
							label={intl.formatMessage(i18n.closeButtonLabel)}
							tooltip={{ content: intl.formatMessage(i18n.closeButtonLabel) }}
							isTooltipDisabled={false}
							appearance="subtle"
							spacing="compact"
							shape="circle"
							testId="miniplayer-close"
							onClick={() => tryCallback(onInitiateClose)}
						/>
					</Box>
				</Flex>
				<MiniplayerControls
					isPlaying={isPlaying}
					onPause={() => tryCallback(onInitiatePause)}
					onResume={() => tryCallback(onInitiateResume)}
					onSkipBack={() => tryCallback(onInitiateSkipBack)}
					onSkipForward={() => tryCallback(onInitiateSkipForward)}
					onChangePlaybackSpeed={(speed) => tryCallback(onChangePlaybackSpeed.bind(this, speed))}
					onPlayFromBeginning={() => tryCallback(onInitiatePlayFromBeginning)}
				/>
				<Box>
					<ProgressBar value={getProgressPercentage()} />
				</Box>
			</Stack>
		</Box>
	);
};
