import React, { Component } from 'react';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';

import { ReturnToMobileComponent } from './ReturnToMobileComponent';

export class ReturnToMobile extends Component {
	render() {
		return (
			<RoutesContext.Consumer>
				{({ getQueryParams, setQueryParams }) => {
					return (
						<ReturnToMobileComponent
							getQueryParams={getQueryParams}
							setQueryParams={setQueryParams}
							{...this.props}
						/>
					);
				}}
			</RoutesContext.Consumer>
		);
	}
}
