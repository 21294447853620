import type { FC } from 'react';
import React, { Fragment, useEffect, useState } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import type { IEditorPreloader } from '@confluence/edit-button';
import { BannerStateContainer } from '@confluence/banners';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { ATL_GENERAL, ATL_FOOTER } from '@confluence/web-item-location';
import { PageLayout } from '@confluence/page-layout/entry-points/PageLayout';
import { WebPanelLocation, useATLGeneralWebPanelObserver } from '@confluence/web-panel-location';
import { ReturnToMobile } from '@confluence/return-to-mobile';
import { PerformanceEnd, PERFORMANCE_SUBJECT_mainLayout } from '@confluence/performance';
import { useRightSidebarContext } from '@confluence/page-layout-context';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';

import type { MainLayoutQuery_space_lookAndFeel_content_screen as MainLayoutScreenType } from './__types__/MainLayoutQuery';
import {
	ContentBodyWrapper,
	MainContentContainer,
	WebPanelWrapper,
	ContentBody,
	GlobalImagePreviewStyling,
	FixedWebPanelWrapper,
	FixedWebPanelSpacer,
} from './styledComponents';

type EditorPreloaderType = (config: IEditorPreloader) => JSX.Element | null;

export type MainLayoutComponentProps = {
	children: React.ReactElement;
	enableNavigation?: boolean;
	disableMinWidth?: boolean;
	screen?: MainLayoutScreenType;
	navView?: string;
	editorPreloader?: () => Promise<() => EditorPreloaderType>;
	isViewPage?: boolean;
	isEdit?: boolean;
};

export const MainLayoutComponent: FC<MainLayoutComponentProps> = ({
	navView,
	enableNavigation = true,
	disableMinWidth,
	screen,
	editorPreloader,
	isViewPage = false,
	isEdit,
	children,
}) => {
	const [preloader, setPreloader] = useState<EditorPreloaderType>();
	const {
		shouldShowATLGeneralWebPanel,
		isATLGeneralWebPanelFixed,
		fixedATLGeneralWebPanelRef,
		isFullWidth,
	} = useATLGeneralWebPanelObserver(isViewPage);
	const isLivePage = useIsLivePage();

	const rightSidebarContext = useRightSidebarContext();
	const [enableRightSidebarCSS, setEnableRightSidebarCSS] = useState(false);

	const topElementStyle = {};
	if (screen) {
		for (const key in screen) {
			if (screen.hasOwnProperty(key)) {
				if (key.startsWith('background')) {
					topElementStyle[key] = screen[key];
				}
			}
		}
	}

	useEffect(() => {
		if (rightSidebarContext.getCurrent()) {
			setEnableRightSidebarCSS(true);
		}
	}, [rightSidebarContext]);

	useEffect(() => {
		void setEditorPreloader();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setEditorPreloader = async () => {
		if (editorPreloader) {
			const preloader = await editorPreloader();
			setPreloader(preloader);
		}
	};

	const AtlGeneralWebPanel = isATLGeneralWebPanelFixed ? (
		<Fragment>
			<FixedWebPanelWrapper ref={fixedATLGeneralWebPanelRef} isFullWidth={isFullWidth}>
				<WebPanelLocation location={ATL_GENERAL} fetchPolicy="cache-first" />
			</FixedWebPanelWrapper>
			<FixedWebPanelSpacer />
		</Fragment>
	) : (
		<WebPanelWrapper>
			<WebPanelLocation location={ATL_GENERAL} fetchPolicy="cache-first" />
		</WebPanelWrapper>
	);

	const content = children ? (
		<ErrorBoundary
			attribution={Attribution.BACKBONE}
			attributes={{
				errorBoundaryId: 'MainLayoutComponent-content',
			}}
		>
			<MainContentContainer
				isEnableRightSidebarEnabled={enableRightSidebarCSS}
				disableMinWidth={disableMinWidth || !enableNavigation}
				data-test-id="confluence-main-content"
			>
				{shouldShowATLGeneralWebPanel && AtlGeneralWebPanel}
				{/*Banner to allow user to switch from desktop to mobile version*/}
				<ReturnToMobile />

				<>
					<Subscribe to={[BannerStateContainer]}>
						{(bannerState: BannerStateContainer) => (
							<ContentBodyWrapper>
								<ContentBody
									data-test-id="content-body"
									id="content-body"
									topElementStyle={topElementStyle}
									bannerHeight={bannerState.getTotalHeight()}
								>
									{children}
								</ContentBody>
							</ContentBodyWrapper>
						)}
					</Subscribe>
					{!isEdit && !isLivePage && (
						<WebPanelWrapper>
							<WebPanelLocation location={ATL_FOOTER} />
						</WebPanelWrapper>
					)}
				</>
			</MainContentContainer>
		</ErrorBoundary>
	) : null;

	return (
		<Fragment>
			<ErrorBoundary
				attribution={Attribution.BACKBONE}
				attributes={{
					errorBoundaryId: 'MainLayoutComponent-all',
				}}
			>
				{enableNavigation ? (
					<PageLayout editorPreloader={preloader} view={navView}>
						{content}
					</PageLayout>
				) : (
					content
				)}
				<ErrorBoundary
					attribution={Attribution.BACKBONE}
					attributes={{
						errorBoundaryId: 'MainLayoutComponent-styles',
					}}
				>
					<GlobalImagePreviewStyling />
					<PerformanceEnd subject={PERFORMANCE_SUBJECT_mainLayout} />
				</ErrorBoundary>
			</ErrorBoundary>
		</Fragment>
	);
};
