import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import { N30 } from '@atlaskit/theme/colors';

import { Cookies } from '@confluence/cookies';
import {
	isMobileUserAgent,
	isDesktopOverrideActive,
	deactivateDesktopOverride,
} from '@confluence/mobile-detection';
import { CONTEXT_PATH } from '@confluence/named-routes';

export interface ReturnToMobileProps {
	createAnalyticsEvent?: (x: any) => any;
	getQueryParams: () => any;
	setQueryParams: (query: object, replace: boolean) => any;
}

export type ReturnToMobileState = {
	isSupportedUserAgent: boolean;
	isDesktopOverride: boolean;
};

const returnLinkContainer = css({
	padding: token('space.200', '16px'),
	background: token('color.background.neutral', N30),
	textAlign: 'center',
});

export const ReturnToMobileComponent = withAnalyticsEvents()(
	class ReturnToMobileComponent extends PureComponent<
		ReturnToMobileProps & WithAnalyticsEventsProps,
		ReturnToMobileState
	> {
		state = {
			isSupportedUserAgent: isMobileUserAgent(),
			// If the mobile SPA is handling mobile view, we need to check local storage
			// TODO: Remove the call to isDesktopSwitchRequired once mobile-plugin is dead
			isDesktopOverride: this.isDesktopSwitchRequired() || isDesktopOverrideActive(),
		};

		componentDidMount() {
			const { createAnalyticsEvent } = this.props;
			const { isSupportedUserAgent } = this.state;

			if (isSupportedUserAgent && createAnalyticsEvent) {
				const analyticsData = {
					source: 'mobileWebView',
					action: 'visited',
					actionSubject: 'page',
				};
				createAnalyticsEvent!({
					type: 'sendOperationalEvent',
					data: analyticsData,
				}).fire();
			}
		}

		isDesktopSwitchRequired() {
			// Port from com.atlassian.confluence.mobile.MobileUtils#isDesktopSwitchRequired
			const { getQueryParams } = this.props;
			const { desktop } = getQueryParams();

			return Cookies.get('confluence.mobile.desktop.switch') === 'true' || desktop === 'true';
		}

		onSwitchClick = () => {
			const { getQueryParams, setQueryParams } = this.props;

			// If the backend filter has not set a cookie, we need to remove it from local storage (the new way)
			// TODO: Remove the call to isDesktopSwitchRequired once mobile-plugin is dead
			if (!this.isDesktopSwitchRequired()) {
				deactivateDesktopOverride();
			} else {
				// Otherwise, we need to remove the cookie
				Cookies.remove('confluence.mobile.desktop.switch', {
					path: CONTEXT_PATH,
				});
			}

			const queryParams = getQueryParams();
			// remove the desktop=true request parameter if it is present
			if (queryParams.desktop === 'true') {
				queryParams.desktop = null; // setQueryParams removes null values
				setQueryParams(queryParams, true);
			} else {
				window.location.reload();
			}
		};

		render() {
			const { isSupportedUserAgent, isDesktopOverride } = this.state;

			// Only show this if the user is on a mobile device and has explicitly chosen to show desktop
			if (isSupportedUserAgent && isDesktopOverride) {
				return (
					<div css={returnLinkContainer}>
						<FormattedMessage
							id="return-to-mobile.nav.switch.to.mobile.text"
							defaultMessage="You are viewing the desktop version of Confluence on a mobile supported device."
							description="Banner message displayed at top of screen when user is in desktop mode on a mobile device."
						/>{' '}
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a href="#" onClick={this.onSwitchClick}>
							<FormattedMessage
								id="return-to-mobile.nav.switch.to.mobile.action"
								defaultMessage="Switch to Confluence Mobile"
								description="Link text to switch back to mobile web mode, title cased"
							/>
						</a>
					</div>
				);
			}

			return null;
		}
	},
);
