import gql from 'graphql-tag';

export const SpaceThemeQuery = gql`
	query SpaceThemeQuery($spaceKey: String) {
		space(key: $spaceKey) {
			id
			theme {
				themeKey
			}
		}
	}
`;
