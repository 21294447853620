import { getUrlForContentType } from '@confluence/content-types-utils';

import type { ActiveAudioContent } from '../types/activeAudioContent';

import { activeAudioContentHasSmartLinkData } from './activeAudioContent';

declare global {
	interface Window {
		__backendOriginHost: string | undefined;
	}
}

const getOrigin = (): string => {
	return window.location.hostname === 'localhost'
		? `https://${window.__backendOriginHost}`
		: window.location.origin;
};

export const getContentUrl = (activeContent: ActiveAudioContent): string | null => {
	if (activeAudioContentHasSmartLinkData(activeContent)) {
		const contentPath = getUrlForContentType({
			contentType: activeContent.contentType,
			contentId: activeContent.id,
			// @ts-ignore Checked by above conditional
			spaceKey: activeContent.spaceKey,
		});

		return `${getOrigin()}${contentPath}`;
	}

	return null;
};
